import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useStyles } from './bannerStyles';

export default function Banner(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <section className={classes.bannerWrapper}>
        <div className={classes.bannerBackground}></div>
        <Container>
          <div className={classes.bannerTitle}>{  props.acf.banner_title }</div>
          <div className={classes.bannerDescription}>
            I’m a <b>Front End Developer</b> and frustrated <span className={classes.bannerJob}>Musician</span> based in the philippines. I make the visual for web applications to be more cool and interactive.
          </div>
          <a href="#contact" className={classes.bannerContactButton}>LET'S TALK</a>
        </Container>
      </section>
    </React.Fragment>
  );
}
