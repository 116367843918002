import React from 'react';
import dayjs from 'dayjs';
import constants from '../../../assets/styles/global';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { useStyles } from './aboutMeStyles';

export default function AboutMe({homePageData, experiencesData, certificatesData}) {
  const classes = useStyles();

  const sortDateByRecent = (a, b) => {
    return new Date(b.acf.date_from).getTime() - new Date(a.acf.date_from).getTime();
  }

  const getCurrentExperience = () =>{
    if (experiencesData){
      let currentWork = experiencesData.filter((item) => { return item.acf.present === true })[0];
      if (currentWork){
        return (
          <div className={classes.listItemContainer}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={5}>
                  <div className={classes.listItemDate}>{ dayjs(currentWork.acf.date_from).format(constants.DATE_DEFAULT_FORMAT) } - Present</div>
                  <div className={classes.listItemInstitute}>{ currentWork.acf.company_name }</div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div className={classes.listItemTitle} dangerouslySetInnerHTML={{ __html:currentWork.acf.job_title }} />
                  <div className={classes.listItemDescription}>{ currentWork.acf.job_description }</div>
                </Grid>
            </Grid>
          </div>
        );
      }

      return null;
    }
    
    return null;
  }

  const getAllPastExperience = () =>{
    if (experiencesData){
      let pastWorks = experiencesData.filter((item) => { return item.acf.present === false }).sort(sortDateByRecent).map((item,index) => {
        return (
          <div key={index} className={classes.listItemContainer}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={5}>
                  <div className={classes.listItemDate}>{ dayjs(item.acf.date_from).format(constants.DATE_DEFAULT_FORMAT) } - { dayjs(item.acf.date_until).format(constants.DATE_DEFAULT_FORMAT) }</div>
                  <div className={classes.listItemInstitute}>{ item.acf.company_name }</div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div className={classes.listItemTitle} dangerouslySetInnerHTML={{ __html:item.acf.job_title }} />
                  <div className={classes.listItemDescription}>{ item.acf.job_description }</div>
                </Grid>
            </Grid>
          </div>
        );
      });

      return pastWorks;
    }
    
    return null;
  }

  const getAllCerticates = () =>{
    if (certificatesData){
      let pastCertificates = certificatesData.sort(sortDateByRecent).map((item,index) => {
        return (
          <div key={index} className={classes.listItemContainer}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={5}>
                  <div className={classes.listItemDate}>{ dayjs(item.acf.date_from).format(constants.DATE_DEFAULT_FORMAT) }</div>
                  <div className={classes.listItemInstitute}>{ item.acf.institute_name }</div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div className={classes.listItemTitle} dangerouslySetInnerHTML={{ __html:item.acf.certificate_title }} />
                  <div dangerouslySetInnerHTML={{ __html: item.acf.certificate_description }} className={classes.listItemDescription} />
                </Grid>
            </Grid>
          </div>
        );
      });

      return pastCertificates;
    }
    
    return null;
  }

  return (
    <React.Fragment>
      <section className={classes.aboutMeWrapper}>
        <Container>
          <div className={classes.sectionTitle}>About Me</div>
          <Grid className={classes.aboutMeFirstSection} container spacing={6}>
            <Grid item xs={12} md={6}>
              <div className={classes.subSection}>
                  <div className={classes.subSectionTitle}>Who <span>Am I?</span></div>
                  <p dangerouslySetInnerHTML={{ __html: homePageData.acf.who_am_i_description }} className={classes.subSectionDescription} />
                  <a className={classes.noUnderline} download target="_blank" rel="noreferrer" href={homePageData.acf.resume_link}>
                    <Button className={classes.downloadButton} startIcon={<DownloadIcon />} size={"large"} variant="contained">Download My Resume</Button>
                  </a>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.subSection}>
                  <div className={classes.subSectionTitle}>Expert <span>In</span></div>
                  <p dangerouslySetInnerHTML={{ __html: homePageData.acf.expert_in_description }} className={classes.subSectionDescription} />
                  <div className={classes.skillBarContainer}>
                    <div className={classes.skillBarTitle}>HTML5</div>
                      <div className={classes.skillBar}>
                        <div style={{width: '90%'}} className={classes.skillBarProgress}>
                          <div className={classes.skillBarProgressLabel}>90%</div>
                        </div>
                      </div>
                  </div>
                  <div className={classes.skillBarContainer}>
                    <div className={classes.skillBarTitle}>CSS</div>
                      <div className={classes.skillBar}>
                        <div style={{width: '90%'}} className={classes.skillBarProgress}>
                          <div className={classes.skillBarProgressLabel}>90%</div>
                        </div>
                      </div>
                  </div>
                  <div className={classes.skillBarContainer}>
                    <div className={classes.skillBarTitle}>JAVASCRIPT</div>
                      <div className={classes.skillBar}>
                        <div style={{width: '85%'}} className={classes.skillBarProgress}>
                          <div className={classes.skillBarProgressLabel}>85%</div>
                        </div>
                      </div>
                  </div>
                  <div className={classes.skillBarContainer}>
                    <div className={classes.skillBarTitle}>Wordpress</div>
                      <div className={classes.skillBar}>
                        <div style={{width: '80%'}} className={classes.skillBarProgress}>
                          <div className={classes.skillBarProgressLabel}>80%</div>
                        </div>
                      </div>
                  </div>
                  <div className={classes.skillBarContainer}>
                    <div className={classes.skillBarTitle}>React.js</div>
                      <div className={classes.skillBar}>
                        <div style={{width: '85%'}} className={classes.skillBarProgress}>
                          <div className={classes.skillBarProgressLabel}>85%</div>
                        </div>
                      </div>
                  </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <div className={classes.subSection}>
                  <div className={classes.subSectionTitle}><span>Ex</span>perience</div>
                  { getCurrentExperience() }
                  { getAllPastExperience() }
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.subSection}>
                  <div className={classes.subSectionTitle}><span>Cert</span>ificate</div>
                  { getAllCerticates() }
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}
