import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import BrushIcon from '@mui/icons-material/Brush';
import CodeIcon from '@mui/icons-material/Code';
import StorageIcon from '@mui/icons-material/Storage';
import BugReportIcon from '@mui/icons-material/BugReport';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useStyles } from './whatIDoStyles';

export default function WhatIDo({homePageData}) {
  const classes = useStyles();

  const getLogo = (logo) => {
    const logoLowerCase = logo.toLowerCase();
    if (logoLowerCase === 'bulb'){
      return <TipsAndUpdatesIcon />
    }
    else if (logoLowerCase === 'brush'){
      return <BrushIcon />
    }
    else if (logoLowerCase === 'code'){
      return <CodeIcon />
    }
    else if (logoLowerCase === 'server'){
      return <StorageIcon />
    }
    else if (logoLowerCase === 'bug'){
      return <BugReportIcon />
    }
    else if (logoLowerCase === 'settings'){
      return <EngineeringIcon />
    }

    return null;
  }

  const getWhatIDoItems = () => {
    if (homePageData){
      let items = homePageData.acf.what_i_do_items.map((item, index) => {
        return (
          <Grid key={index} item xs={12} md={4}>
            <div className={classes.whatIDoContainer}>
              <span className={classes.whatIDoLogo}>{ getLogo(item.icon) }</span>
              <span className={classes.whatIDoName} dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
            <div className={classes.whatIDoDescription}>{ item.description }</div>
          </Grid>
        )
      });

      return items;
    }

    return null;
  }

  return (
    <React.Fragment>
      <section className={classes.sectionWrapper}>
        <Container className={classes.sectionContainer}>
          <div className={classes.sectionTitle}>What I Do</div>
          <Grid className={classes.firstRow} container spacing={2}>
            { getWhatIDoItems() }
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}
