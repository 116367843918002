import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';
import SectionBackground from '../../../assets/images/coffee-cup-desk-317385.jpg';

export const useStyles = makeStyles({
    sectionWrapper: {
        height: 40,
        background: constants.COLOR_BLACK,
        padding: 10
    },
    copyright: {
        fontSize: constants.FONT_XS,
        color: constants.COLOR_WHITE,
        textAlign: 'center',
        fontWeight: 300
    }
});