import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';

export const useStyles = makeStyles({
    sectionWrapper: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
        overflow: 'hidden',
        padding: '40px 30px',
    },
    sectionTitleContainer: {
        position: 'relative'
    },
    sectionTitle:{
        position: 'relative',
        fontSize: constants.FONT_XL,
        color: constants.COLOR_PRIMARY,
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'left',
        top: 0,
        zIndex: 5,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            fontSize: constants.FONT_LG,
        }
    },
    sectionTitleLines: {
        position: 'relative',
        top: 0,
        left: 0,
        background: constants.COLOR_BLACK,
        height: 1,
        width: 110,
        marginBottom: 50,
        '&:before':{
            content:'""',
            position: 'absolute',
            top: 10,
            left: 0,
            background: constants.COLOR_BLACK,
            height: 1,
            width: 70
        },
        '&:after':{
            content:'""',
            position: 'absolute',
            top: 20,
            left: 0,
            background: constants.COLOR_BLACK,
            height: 1,
            width: 40
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            width: 120,
            '&:before':{
                width: 50
            },
            '&:after':{
                width: 30
            },
        }
    },
    contactDetailContainer: {
        marginBottom: 20
    },
    contactDetailIcon: {
        display: 'inline-block',
        border: `1px solid ${constants.COLOR_BLACK}`,
        padding: 6,
        height: 36,
        width: 36,
        textAlign: 'center',
        float: 'left',
        color: constants.COLOR_BLACK,
        '& svg':{
            fontSize: constants.FONT_MD,
        }
    },
    contactDetailValue: {
        transition: '0.2s ease-out all',
        fontSize: constants.FONT_MD,
        color: constants.COLOR_BLACK,
        textDecoration: 'none',
        '&:hover':{
            color: constants.COLOR_PRIMARY,
            textDecoration: 'underline',
            cursor: 'pointer'
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            position: 'relative',
            top: 4,
            fontSize: constants.FONT_SM,
            marginLeft: 6,
        }
    },
    socialMediaContainer: {
        marginTop: 20,
        display: 'block'
    },
    socialMediaButton: {
        transition: '0.2s ease-out all',
        display: 'inline-block',
        border: `1px solid ${constants.COLOR_BLACK}`,
        padding: 6,
        height: 36,
        width: 36,
        textAlign: 'center',
        float: 'left',
        color: constants.COLOR_BLACK,
        cursor: 'pointer',
        '& svg':{
            fontSize: constants.FONT_MD,
            color: constants.COLOR_BLACK,
        },
        '&:hover':{
            borderColor: constants.COLOR_PRIMARY_DARK,
            background: constants.COLOR_PRIMARY_DARK,
            '& svg':{
                color: constants.COLOR_WHITE
            }
        }
    },
    formInput: {
        color: constants.COLOR_BLACK,
        fontSize: constants.FONT_MD,
        [`@media (max-width:${constants.BREAKPOINT_TABLET})`]: {
            fontSize: constants.FONT_SM
        }
    },
    formSubmit: {
        marginTop: '20px !important',
        display: 'block',
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            fontSize: `${constants.FONT_SM} !important`,
            width: '100%',
        }
    }
});