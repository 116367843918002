import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';
import SectionBackground from '../../../assets/images/iphone-mobile.jpg';

export const useStyles = makeStyles({
    sectionWrapper: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
        overflow: 'hidden',
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${SectionBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        padding: '40px 30px',
        height: 600,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            height: '100%',
        }
    },
    sectionTitleContainer: {
        position: 'relative'
    },
    sectionTitle:{
        position: 'relative',
        fontSize: constants.FONT_XL,
        color: constants.COLOR_WHITE,
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'left',
        top: 0,
        zIndex: 5,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            fontSize: constants.FONT_LG,
        }
    },
    sectionTitleLines: {
        position: 'relative',
        top: 0,
        left: 0,
        background: constants.COLOR_WHITE,
        height: 1,
        width: 190,
        marginBottom: 50,
        '&:before':{
            content:'""',
            position: 'absolute',
            top: 10,
            left: 0,
            background: constants.COLOR_WHITE,
            height: 1,
            width: 70
        },
        '&:after':{
            content:'""',
            position: 'absolute',
            top: 20,
            left: 0,
            background: constants.COLOR_WHITE,
            height: 1,
            width: 40
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            width: 120,
            '&:before':{
                width: 50
            },
            '&:after':{
                width: 30
            },
        }
    },
    feedbackContainer: {
        width: 500,
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]: {
            width: 400,
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            width: '100%',
        }
    },
    feedback: {
        color: constants.COLOR_WHITE,
        fontSize: constants.FONT_MD,
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]: {
            fontSize: constants.FONT_SM,
        }
    },
    feedbackLogo: {
        transform: 'rotateY(180deg)',
        display: 'inline-block',
        '& svg':{
            fontSize: constants.FONT_XXL
        }
    },
    clientName: {
        marginTop: 30,
        marginBottom: 30
    },
    clientPosition: {},
    clientContainer: {},
    clientThumbnail: {
        transition: '0.1s ease-out all',
        height: 130,
        width: 130,
        border: `2px solid ${constants.COLOR_WHITE}`,
        cursor: 'pointer',
        '& img':{
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        },
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]: {
            height: 80,
            width: 80,
        }
    },
    clientThumbnailActive: {
        transform: 'scale(1.1)'
    }
});