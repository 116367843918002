import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';
import SectionBackground from '../../../assets/images/coffee-cup-desk-317385.jpg';

export const useStyles = makeStyles({
    sectionWrapper: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
        overflow: 'hidden',
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${SectionBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        paddingBottom: 60,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            paddingBottom: 0
        }
    },
    sectionTitle:{
        position: 'relative',
        fontSize: constants.FONT_XL,
        color: constants.COLOR_WHITE,
        fontWeight: 700,
        marginBottom: 120,
        textTransform: 'uppercase',
        textAlign: 'center',
        top: 30,
        zIndex: 5,
        width: 300,
        margin: '0 auto',
        '&:before':{
            content:'""',
            position: 'absolute',
            top: 60,
            left: 88,
            background: constants.COLOR_WHITE,
            height: '1px',
            width: '120px'
        },
        '&:after':{
            content:'""',
            position: 'absolute',
            top: 70,
            left: 108,
            background: constants.COLOR_WHITE,
            height: '1px',
            width: 78
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            marginBottom: 70
        }
    },
    whatIDoContainer: {
        marginBottom: 10,
    },
    whatIDoLogo: {
        color: constants.COLOR_WHITE,
        position: 'relative',
        top: 16,
        left: 0,
        marginRight: 10,
        '& svg':{
            fontSize: '50px'
        },
    },
    whatIDoName: {
        fontSize: constants.FONT_LG,
        color: constants.COLOR_WHITE,
        fontWeight: 300,
        textTransform: 'uppercase',
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            fontSize: constants.FONT_MD,
        }
    },
    whatIDoDescription: {
        fontSize: constants.FONT_MD,
        color: constants.COLOR_WHITE,
        paddingLeft: 60,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            fontSize: constants.FONT_SM,
        }
    },
    firstRow: {
        marginBottom: 70
    }
});