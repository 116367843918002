import React from 'react';
import { useStyles } from './footerStyles';

function Footer(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <section className={classes.sectionWrapper}>
        <div className={classes.copyright}>{ props.acf.copyright }</div>
      </section>
    </React.Fragment>
  );
}

export default Footer;
