import { makeStyles } from '@material-ui/styles'
import constants from '../../assets/styles/global';

export const useStyles = makeStyles({
    modalContainer: {
        background: constants.COLOR_WHITE,
        width: 500,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        border: 'none',
        outline: 'none'
    },
    modalIcon: {
        position: 'relative',
        padding: 16,
        textAlign: 'center',
        '& svg':{
            fontSize: '72px',
            color: constants.COLOR_WHITE
        },
    },
    modalIconSuccess: {
        background: constants.COLOR_SUCCESS,
    },
    modalIconError: {
        background: constants.COLOR_ERROR,
    },
    modalCloseIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer',
        color: constants.COLOR_WHITE,
        '& svg':{
            fontSize: constants.FONT_LG
        }
    },
    modalContent: {
        padding: 10
    },
    modalTitle:{
        margin: 0,
        fontFamily: constants.PRIMARY_FONT,
        fontSize: constants.FONT_LG,
        textAlign: 'center'
    },
    modalDescription: {
        fontFamily: constants.PRIMARY_FONT,
        fontSize: constants.FONT_MD,
        textAlign: 'center'
    },
    loadingAnimation:{
        position: 'absolute',
        '& #codingAnimation':{
            width: '400px !important',
            height: '400px !important',
            top: '40%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%) !important',
            [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
                width: '300px !important',
                height: '300px !important',
            }
        },
    },
    loadingText: {
        position: 'absolute',
        fontSize: constants.FONT_MD,
        '& #loadingAnimation':{
            width: '200px !important',
            height: '100px !important',
            left: '40%',
            top: 120,
            transform: 'translateX(-50%) !important',
            position: 'absolute',
            [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
                top: 80,
                width: '180px !important',
                height: '80px !important',
            }
        },
    }
});