import { darken } from '@material-ui/core/styles';

const constants = {
    ROOT_CONTAINER_MAX_WIDTH: '1440px !important',
    ROOT_FONT_SIZE: '10px',

    COLOR_PRIMARY: '#3399FF',
    COLOR_PRIMARY_DARK: darken('#3399FF',0.1),
    COLOR_SECONDARY: '',
    COLOR_WHITE: '#ffffff',
    COLOR_BLACK: '#3F3F3F',
    COLOR_BLACK_OTHER: '3399FF',
    COLOR_GRAY: '#E2E2E2',
    COLOR_GRAY_LABEL: '#B1B0B0',

    COLOR_SUCCESS: '#47C9A2',
    COLOR_ERROR: '#E85E6C',

    PRIMARY_FONT: ['Lato','Calibri'].join(','),

    FONT_XS: '1.2rem',
    FONT_SM: '1.6rem',
    FONT_MD: '2rem',
    FONT_LG: '2.8rem',
    FONT_XL: '4rem',
    FONT_XXL: '4.8rem',

    BREAKPOINT_MOBILE: '480px',
    BREAKPOINT_TABLET: '1024px',
    BREAKPOINT_SMALL_SCREEN: '1160px',
    BREAKPOINT_LARGE_SCREEN: '1400px',
    BREAKPOINT_WIDE_SCREEN: '1920px',

    DATE_DEFAULT_FORMAT: 'MMM YYYY',
}

export default constants;