import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL
const contactApiUrl = process.env.REACT_APP_CONTACT_API_URL

const contactFormId = 86;

export function getHomePageData(){
    const response = axios.get(apiUrl + '/pages?slug=home-page');
    return response;
}

export function getExperiencesData(){
    const response = axios.get(apiUrl + '/experience');
    return response;
}

export function getCertificatesData(){
    const response = axios.get(apiUrl + '/certificate');
    return response;
}

export function postSendContactUsEmail(bodyFormData){
    const response = axios.post(contactApiUrl + `/${contactFormId}/feedback`, bodyFormData);
    return response;
}