import React from 'react';
import { useRef, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Slider from "react-slick";
import Image1 from '../../../assets/images/administration-adult-banking-1056549.jpg';
import Image2 from '../../../assets/images/analog-camera-attractive-beautiful-1960183.jpg';
import Image3 from '../../../assets/images/arched-window-architecture-building-2076966.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useStyles } from './feedbackStyles';

export default function Feedback() {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  }
  const feedbackSliderRef = useRef();

  function goToFeedback(index){
    if (index){
      feedbackSliderRef.current.slickGoTo(index-1);
      setSlideIndex(index-1);
    }
  }

  return (
    <React.Fragment>
      <section className={classes.sectionWrapper}>
        <Container>
          <div className={classes.sectionTitleContainer}>
            <div className={classes.sectionTitle}>Client's Feedback</div>
            <div className={classes.sectionTitleLines} />
          </div>
          <Grid container spacing={2} >
            <Grid item xs={12} md={5}>
              <Slider ref={feedbackSliderRef} className={classes.feedbackContainer} {...sliderSettings}>
                <div className={classes.feedback}>
                  <div className={classes.feedbackLogo}>
                    <FormatQuoteIcon />
                  </div>
                  Lorem ipsum dolor sit amet, cu vel officiis tacimates gloriatur. Velit eleifend no has, sed at quot possim. Vis eu quodsi appetere. Per dolores repudiare  cotidieque id. Sint democritum reformidans mei id, dolorum atomorum
                  <div className={classes.clientName}>Sam, <span className={classes.clientPosition}>Marketing Head</span></div>
                </div>
                <div className={classes.feedback}>
                  <div className={classes.feedbackLogo}>
                    <FormatQuoteIcon />
                  </div>
                  Lorem ipsum dolor sit amet, cu vel officiis tacimates gloriatur. Velit eleifend no has, sed at quot possim. Vis eu quodsi appetere. Per dolores repudiare  cotidieque id. Sint democritum reformidans mei id, dolorum atomorum
                  <div className={classes.clientName}>Sunny, <span className={classes.clientPosition}>CEO</span></div>
                </div>
                <div className={classes.feedback}>
                  <div className={classes.feedbackLogo}>
                    <FormatQuoteIcon />
                  </div>
                  Lorem ipsum dolor sit amet, cu vel officiis tacimates gloriatur. Velit eleifend no has, sed at quot possim. Vis eu quodsi appetere. Per dolores repudiare  cotidieque id. Sint democritum reformidans mei id, dolorum atomorum
                  <div className={classes.clientName}>Dominic, <span className={classes.clientPosition}>CEO</span></div>
                </div>
              </Slider>
              <div className={classes.clientContainer}>
                <Grid container spacing={2}>
                  <Grid item>
                    <div onClick={() => goToFeedback(1)} className={[classes.clientThumbnail, slideIndex === 0 ? classes.clientThumbnailActive : ""].join(" ")}>
                      <img alt="sample" src={Image1} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div onClick={() => goToFeedback(2)} className={[classes.clientThumbnail, slideIndex === 1 ? classes.clientThumbnailActive : ""].join(" ")}>
                      <img alt="sample" src={Image2} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div onClick={() => goToFeedback(3)} className={[classes.clientThumbnail, slideIndex === 2 ? classes.clientThumbnailActive : ""].join(" ")}>
                      <img alt="sample" src={Image3} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}
