import { makeStyles, } from '@material-ui/styles'
import constants from '../../../assets/styles/global';

export const useStyles = makeStyles({
    headerWrapper: {
        position: 'absolute',
        top: '0',
        left: '50%',
        width: '100%',
        zIndex: 10,
        transform: 'translateX(-50%)'
    },
    logo: {
        fontSize: constants.FONT_LG,
        textTransform: 'uppercase',
        fontFamily: constants.PRIMARY_FONT,
        fontWeight: 700,
        color: constants.COLOR_WHITE,
        '& span': {
            fontWeight: 400,
            marginRight: 6
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            fontSize: constants.FONT_MD,
            position: 'relative',
            marginTop: 30
        }
    },
    headerMenu: {
        position: 'relative',
        top: 24,
        left: 0,
        '& li':{
            display: 'inline-block',
            marginLeft: '16px',
            marginRight: '16px',
            textTransform: 'uppercase',
            textAlign: 'right',
            fontSize: constants.FONT_MD,
            '& a':{
                transition: 'all 0.3s ease-out',
                color: constants.COLOR_WHITE,
                borderBottom: '3px solid transparent',
                paddingBottom: 4,
                textDecoration: 'none',
                '&:hover':{
                    borderColor: constants.COLOR_WHITE
                },
            },
            '&:last-child':{
                marginRight: '0px',
                '& a':{
                    borderColor: constants.COLOR_WHITE,
                    paddingBottom: 14,
                    '&:hover':{
                        background: constants.COLOR_WHITE,
                        borderColor: constants.COLOR_WHITE,
                        color: constants.COLOR_BLACK
                    }
                }
            }
        },
        '& ul':{
            [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]: {
                display: 'none'
            }
        }
    },
    headerMenuItemOutlined: {
        '& a':{
            transition: 'all 0.3s ease-out',
            border: `2px solid ${constants.COLOR_WHITE}`,
            padding: 14,
        }
    },
    mobileMenuButton:{
        display: 'none',
        position: 'absolute',
        right: 10,
        '& svg':{
            fontSize: constants.FONT_XXL,
            color: constants.COLOR_WHITE,
            cursor: 'pointer'
        },
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]: {
            display: 'inline-block'
        }
    },
    headerMobileMenu: {
        listStyle: 'none',
        width: '200px',
        '& ul':{
            padding: 0
        },
        '& li':{
            display: 'block',
            textAlign: 'center',
            fontSize: constants.FONT_MD,
            marginBottom: 16,
            paddingLeft: 10,
            paddingRight: 10,
            '& a':{
                color: constants.COLOR_BLACK,
                textDecoration: 'none'
            }
        }
    }
});