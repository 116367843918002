import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';

export const useStyles = makeStyles({
    aboutMeWrapper: {
        padding: '40px 20px'
    },
    aboutMeFirstSection: {
        marginBottom: 30
    },
    sectionTitle:{
        position: 'relative',
        fontSize: constants.FONT_XL,
        color: constants.COLOR_PRIMARY,
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: 60,
        textTransform: 'uppercase',
        width: '300px',
        margin: '0 auto',
        '&:before':{
            content:'""',
            position: 'absolute',
            top: 60,
            left: 88,
            background: constants.COLOR_BLACK,
            height: '1px',
            width: '120px'
        },
        '&:after':{
            content:'""',
            position: 'absolute',
            top: 70,
            left: 108,
            background: constants.COLOR_BLACK,
            height: '1px',
            width: 78
        },
        [`@media (max-width:${constants.BREAKPOINT_TABLET})`]:{
            fontSize: constants.FONT_LG,
            width: '100%',
            marginBottom: 40,
            '&:before':{
                display: 'none'
            },
            '&:after':{
                display: 'none'
            },
        }
    },
    subSection: {

    },
    subSectionTitle: {
        fontSize: constants.FONT_LG,
        textTransform: 'uppercase',
        fontWeight: 300,
        marginBottom: 10,
        '& span': {
            fontWeight: 700,
            color: constants.COLOR_PRIMARY
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            fontSize: constants.FONT_MD,
        }
    },
    subSectionDescription: {
        fontSize: constants.FONT_MD,
        lineHeight: '24px',
        marginBottom: 20,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            fontSize: constants.FONT_SM,
        }
    },
    noUnderline: {
        textDecoration: 'none'
    },
    downloadButton: {
        '& svg':{
          '&:first-child':{
            fontSize: constants.FONT_LG
          }
        },
        [`@media (max-width:${constants.BREAKPOINT_TABLET})`]:{
            fontSize: `${constants.FONT_XS} !important`,
            width: '100%',
            '& svg':{
              '&:first-child':{
                fontSize: `${constants.FONT_MD} !important`
              }
            },
        }
    },
    skillBarContainer: {
        marginBottom: 10
    },
    skillBarTitle: {
        fontSize: constants.FONT_MD,
        textTransform: 'uppercase'
    },
    skillBar: {
        backgroundColor: constants.COLOR_GRAY,
        height: '12px',
        width: '100%'
    },
    skillBarProgress: {
        position: 'relative',
        display: 'inline-block',
        height: '100%',
        backgroundColor: constants.COLOR_PRIMARY
    },
    skillBarProgressLabel: {
        position: 'absolute',
        top: -20,
        right: 0,
        backgroundColor: constants.COLOR_PRIMARY,
        color: constants.COLOR_WHITE,
        padding: '2px 4px',
        fontSize: constants.FONT_XS
    },
    listItemContainer: {
        marginBottom: 20,
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            marginBottom: 40,
            '&:last-child':{
                marginBottom: 0
            }
        }
    },
    listItemDate: {
        fontSize: constants.FONT_SM,
        color: constants.COLOR_BLACK,
        fontWeight: 300,
        marginBottom: 4
    },
    listItemInstitute: {
        fontSize: constants.FONT_SM,
        color: constants.COLOR_PRIMARY,
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    listItemTitle: {
        fontSize: constants.FONT_MD,
        color: constants.COLOR_BLACK_OTHER,
        fontWeight: 400,
        marginBottom: 10,
        textTransform: 'uppercase',
        '& span':{
            color: constants.COLOR_PRIMARY,
            fontWeight: 700
        }
    },
    listItemDescription: {
        fontSize: constants.FONT_SM,
        '& a':{
            color: constants.COLOR_PRIMARY,
            '&:hover':{
                color: constants.COLOR_PRIMARY_DARK,
            }
        }
    }
});