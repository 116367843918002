import React from 'react';
import ImagesLoaded from 'react-images-loaded';
import { useState } from 'react';
import constants from './assets/styles/global';
import Home from './components/homepage/home';
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html:{
          fontSize: constants.ROOT_FONT_SIZE,
          fontFamily: constants.PRIMARY_FONT,
        },
        body: {
          fontSize: constants.ROOT_FONT_SIZE,
          fontFamily: constants.PRIMARY_FONT
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: constants.FONT_MD,
          borderRadius: 0
        },
        contained: {
          backgroundColor: constants.COLOR_PRIMARY,
          '&:hover':{
            backgroundColor: constants.COLOR_PRIMARY_DARK
          }
        }
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: constants.ROOT_CONTAINER_MAX_WIDTH
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: constants.FONT_MD,
          [`@media (max-width:${constants.BREAKPOINT_TABLET})`]: {
              fontSize: constants.FONT_XS
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: constants.FONT_MD,
          color: constants.COLOR_GRAY_LABEL,
          [`@media (max-width:${constants.BREAKPOINT_TABLET})`]: {
              fontSize: constants.FONT_XS
          }
        }
      }
    }
  }
});

function App() {
  const [isImagesLoading, setImagesLoading] = useState(true);

  const afterImagesLoaded = () => {
    setTimeout(() => {
      setImagesLoading(false);
    }, 4000);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <section className="App">
      <ImagesLoaded
        done={afterImagesLoaded}
      >
          <Home isImagesLoading={isImagesLoading} />
      </ImagesLoaded>
      </section>
    </ThemeProvider>
  );
}

export default App;
