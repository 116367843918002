import React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import Stack from '@mui/material/Stack';
import { postSendContactUsEmail } from '../../../services/homepageService';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useStyles } from './contactStyles';

export default function Contact({homePageData, showModalMessage, hideModalMessage}) {
  const [contactLoading, setContactLoading] = useState(false);
  const formNameInput = React.useRef(null);
  const formEmailInput = React.useRef(null);
  const formSubjectInput = React.useRef(null);
  const formMessageInput = React.useRef(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const classes = useStyles();

  const resetContactForm = () => {
    setName(null);
    setEmail(null);
    setSubject(null);
    setMessage(null);
    formNameInput.current.value = "";
    formEmailInput.current.value = "";
    formSubjectInput.current.value = "";
    formMessageInput.current.value = "";
  }

  const sendContactEmail = async () => {
    setContactLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('contactName', name);
    bodyFormData.append('contactEmail', email);
    bodyFormData.append('contactSubject', subject);
    bodyFormData.append('contactMessage', message);
    const response = await postSendContactUsEmail(bodyFormData);
    if (response.data?.invalid_fields.length < 1){
      showModalMessage('Email Sent!',"Thank you, I'll contact you as soon as possible.",1);
      resetContactForm();
    }
    else{
      showModalMessage('Oops!','Please correct field items to continue',3);
    }
    setContactLoading(false);
  }

  return (
    <React.Fragment>
      <section className={classes.sectionWrapper}>
        <Container>
          <Grid container spacing={2} >
            <Grid item xs={12} md={5}>
              <div className={classes.sectionTitleContainer}>
                <div className={classes.sectionTitle}>Contact</div>
                <div className={classes.sectionTitleLines} />
              </div>
              <div className={classes.contactDetailContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={1}>
                    <div className={classes.contactDetailIcon}>
                      <EmailIcon />
                    </div>
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <a href="mailto:bantiling.angelo@gmail.com" className={classes.contactDetailValue}>{ homePageData.acf.contact_email }</a>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.contactDetailContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={1}>
                    <div className={classes.contactDetailIcon}>
                      <PhoneAndroidIcon />
                    </div>
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <a href="tel:09754799900" className={classes.contactDetailValue}>{ homePageData.acf.contact_number }</a>
                  </Grid>
                </Grid>
              </div>
              <Stack className={classes.socialMediaContainer} direction={{xs: 'row', md:'row'}} spacing={2}>
                <a target="_blank" rel="noreferrer" href={homePageData.acf.facebook_link} className={classes.socialMediaButton}>
                  <FacebookIcon />
                </a>
                <a target="_blank" rel="noreferrer" href={homePageData.acf.linkedin_link} className={classes.socialMediaButton}>
                  <LinkedInIcon />
                </a>
              </Stack>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField inputRef={formNameInput} onChange={e => setName(e.target.value)} className={classes.formInput} fullWidth label="Name" variant="outlined" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField inputRef={formEmailInput} onChange={e => setEmail(e.target.value)} className={classes.formInput} fullWidth label="Email" variant="outlined" />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField inputRef={formSubjectInput} onChange={e => setSubject(e.target.value)} className={classes.formInput} fullWidth label="Subject" variant="outlined" />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField inputRef={formMessageInput} onChange={e => setMessage(e.target.value)} className={classes.formInput} fullWidth label="Message" variant="outlined" multiline rows={6} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <LoadingButton onClick={sendContactEmail} loading={contactLoading} className={classes.formSubmit} variant="contained">Send</LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}
