import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';

export const useStyles = makeStyles({
    sectionWrapper: {
        padding: '40px 20px'
    },
    aboutMeFirstSection: {
        marginBottom: 30
    },
    sectionTitle:{
        position: 'relative',
        fontSize: constants.FONT_XL,
        color: constants.COLOR_PRIMARY,
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: 60,
        textTransform: 'uppercase',
        width: '400px',
        margin: '0 auto',
        '&:before':{
            content:'""',
            position: 'absolute',
            top: 60,
            left: 140,
            background: constants.COLOR_BLACK,
            height: '1px',
            width: '120px'
        },
        '&:after':{
            content:'""',
            position: 'absolute',
            top: 70,
            left: 160,
            background: constants.COLOR_BLACK,
            height: '1px',
            width: 78
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]: {
            fontSize: constants.FONT_LG,
            width: '100%',
            marginBottom: 20,
            '&:before':{
                display: 'none',
            },
            '&:after':{
                display: 'none',
            },
        }
    },
    workCategoryContainer: {
        margin: '0 auto',
        width: 860,
        marginBottom: 40,
        '& .MuiButton-outlined':{
            border: `3px solid ${constants.COLOR_PRIMARY}`,
            boxSizing: 'border-box',
            fontSize: constants.FONT_SM,
            fontWeight: 700,
            '&:hover':{
                borderWidth: 3,
                borderColor: constants.COLOR_PRIMARY,
                backgroundColor: constants.COLOR_PRIMARY,
                color: constants.COLOR_WHITE
            },
            [`@media (max-width:${constants.BREAKPOINT_TABLET})`]:{
                fontSize: constants.FONT_XS,
            }
        },
        [`@media (max-width:${constants.BREAKPOINT_TABLET})`]:{
            width: '100%'
        }
    },
    workCategoryButtonActive:{
        border: `3px solid ${constants.COLOR_PRIMARY}`,
        boxSizing: 'border-box',
        borderColor: constants.COLOR_PRIMARY,
        backgroundColor: constants.COLOR_PRIMARY,
        color: constants.COLOR_WHITE
    },
    workItemContainer: {
        position: 'relative',
        '&:before':{
            content: '""',
            transition: '0.3s all ease-out',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '99%',
            background: 'transparent'
        },
        '&:hover':{
            cursor: 'pointer',
            '&:before':{
                backgroundColor: 'rgba(0,0,0,0.35)'
            },
            '& span':{
                color: constants.COLOR_WHITE,
                '&:before':{
                    backgroundColor: constants.COLOR_WHITE
                },
                '&:after':{
                    backgroundColor: constants.COLOR_WHITE
                },
            },
        }
    },
    workItemImage: {
        transition: '0.3s all ease-out',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    workItemLabel: {
        transition: '0.3s all ease-out',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: constants.FONT_MD,
        color: 'transparent',
        textTransform: 'uppercase',
        [`@media (max-width:${constants.BREAKPOINT_TABLET})`]:{
            textAlign: 'center'
        }
    },
    workModalContainer: {
        position: 'absolute',
        background: constants.COLOR_WHITE,
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none'
    },
    workModalSlider: {
        height: '80%'
    },
    modalSlide: {
        height: '100%',
        width: '100%',
    },
    modalImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    pictureChoiceSliderContainer: {
        paddingTop: 14,
        paddingLeft: 20,
        paddingRight: 10,
        marginTop: 24,
        '& .slick-slide':{
            paddingRight: 10,
            '&:last-child':{
                paddingRight: 0
            }
        },
        '& button':{
            '&:before':{
                color: constants.COLOR_BLACK
            },
            '&.slick-prev':{
                left: 0,
                zIndex: 99
            },
            '&.slick-next':{
                right: -10,
                zIndex: 99
            }
        }
    },
    pictureChoiceSlide: {
        transition: '0.2s ease-out all',
        overflow: 'hidden',
        cursor: 'pointer',
        border: `2px solid transparent`,
        '& img':{
            height: '100%',
            width: '100%'
        },
    },
    pictureChoiceSlideCurrent: {
        borderColor: constants.COLOR_BLACK
    },
    closeModalIcon: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        '& svg':{
            fontSize: constants.FONT_LG
        }
    },
    workModalContent: {
        marginTop: 4,
        overflowY: 'auto',
        maxHeight: 720,
        paddingLeft: 4,
        paddingRight: 4
    },
    title: {
        fontSize: constants.FONT_LG
    },
    projectClient: {
        fontSize: constants.FONT_SM,
        fontWeight: 300
    },
    projectDate: {
        fontSize: constants.FONT_SM,
        fontWeight: 300
    },
    projectDescription: {
        fontSize: constants.FONT_MD
    },
    projectTechnologies: {},
    technologiesTitle: {
        fontSize: constants.FONT_MD
    },
    technologiesList: {
        marginBottom: 20,
        display: 'block',
        '& li':{
            fontSize: constants.FONT_MD
        }
    },
    projectViewButton: {
        fontSize: `${constants.FONT_SM} !important`
    }
});