import React from 'react';
import { useRef,useState } from 'react';
import Slider from "react-slick";
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Box, Slide } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Image1 from '../../../assets/images/app-background-blur-545331.jpg';
import Image2 from '../../../assets/images/architect-architecture-black-and-white-1537008.jpg';
import { useStyles } from './awesomeWorksStyles';

export default function AwesomeWorks() {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  /* PICTURE SLIDER */
  
  const currentIndexAfterChange = (index) => {
    pictureChoiceSliderRef.current.slickGoTo(index-1);
    setSliderIndex(index);
  }

  const updateCurrentShownImage = (index) => {
    pictureSliderRef.current.slickGoTo(index);
    setSliderIndex(index);
  }
  
  const [sliderIndex, setSliderIndex] = useState(0);
  const pictureSliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    infinite: false,
    afterChange: currentIndexAfterChange
  }
  const pictureSliderRef = useRef();

  /******************/

  /* PICTURE CHOICE SLIDER */

  const pictureChoiceSliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    fade: false,
    infinite: false
  }
  const pictureChoiceSliderRef = useRef();

  /*************************/

  const showWorkModal = () => {
    setShowModal(true);
  }

  const closeWorkModal = () => {
    setShowModal(false);
  }

  return (
    <React.Fragment>
      <section className={classes.sectionWrapper}>
        <Container>
          <div className={classes.sectionTitle}>Awesome Works</div>
          <Stack className={classes.workCategoryContainer} direction={{xs: 'column', md:'row'}} spacing={2}>
            <Button variant="outlined">All Projects</Button>
            <Button variant="outlined">Website</Button>
            <Button variant="outlined">Backend Development</Button>
            <Button variant="outlined">Wordpress</Button>
            <Button variant="outlined">React.js</Button>
          </Stack>
          <Grid container spacing={2}>
            <Grid onClick={showWorkModal} item xs={12} md={6}>
              <div className={classes.workItemContainer}>
                <img className={classes.workItemImage} src={Image1} />
                <span className={classes.workItemLabel}>Music Store</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.workItemContainer}>
                <img className={classes.workItemImage} src={Image2} />
                <span className={classes.workItemLabel}>Software Architecture</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.workItemContainer}>
                <img className={classes.workItemImage} src={Image1} />
                <span className={classes.workItemLabel}>Music Store</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.workItemContainer}>
                <img className={classes.workItemImage} src={Image2} />
                <span className={classes.workItemLabel}>Software Architecture</span>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Modal open={showModal}>
            <Slide direction="left" in={showModal}>
               <Box className={classes.workModalContainer}>
                  <Grid style={{height: '100%'}} container spacing={2}>
                    <Grid style={{height: '100%'}} item xs={12} md={7}>
                      <Slider ref={pictureSliderRef} className={classes.workModalSlider} {...pictureSliderSettings}>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image1} />
                          </div>
                        </Box>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image2} />
                          </div>
                        </Box>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image1} />
                          </div>
                        </Box>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image2} />
                          </div>
                        </Box>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image1} />
                          </div>
                        </Box>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image2} />
                          </div>
                        </Box>
                        <Box>
                          <div className={classes.modalSlide}>
                              <img className={classes.modalImage} src={Image1} />
                          </div>
                        </Box>
                      </Slider>
                      <Box className={classes.workModalSelector}>
                        <Slider ref={pictureChoiceSliderRef} className={classes.pictureChoiceSliderContainer} {...pictureChoiceSliderSettings}>
                          <div onClick={() => updateCurrentShownImage(0)} className={[classes.pictureChoiceSlide, sliderIndex === 0 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image1} />
                          </div>
                          <div onClick={() => updateCurrentShownImage(1)} className={[classes.pictureChoiceSlide, sliderIndex === 1 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image2} />
                          </div>
                          <div onClick={() => updateCurrentShownImage(2)} className={[classes.pictureChoiceSlide, sliderIndex === 2 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image1} />
                          </div>
                          <div onClick={() => updateCurrentShownImage(3)} className={[classes.pictureChoiceSlide, sliderIndex === 3 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image2} />
                          </div>
                          <div onClick={() => updateCurrentShownImage(4)} className={[classes.pictureChoiceSlide, sliderIndex === 4 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image1} />
                          </div>
                          <div onClick={() => updateCurrentShownImage(5)} className={[classes.pictureChoiceSlide, sliderIndex === 5 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image2} />
                          </div>
                          <div onClick={() => updateCurrentShownImage(6)} className={[classes.pictureChoiceSlide, sliderIndex === 6 ? classes.pictureChoiceSlideCurrent : ''].join(" ")}>
                            <img className={classes.modalImage} src={Image1} />
                          </div>
                        </Slider>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Box className={classes.workModalContent} >
                        <Box className={classes.titleContainer}>
                          <Box className={classes.title}>
                            Honda Philippines Web Portal v2
                          </Box>
                          <Box className={classes.closeModalIcon}>
                            <CloseIcon onClick={closeWorkModal} />
                          </Box>
                        </Box>
                        <Box className={classes.projectClient}>Honda Philippines</Box>
                        <Box className={classes.projectDate}>August 06, 1994</Box>
                        <p className={classes.projectDescription}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet orci mauris. Integer at pharetra elit. Praesent at ligula sed tellus aliquet accumsan ut in diam. Cras volutpat enim ligula, ac commodo erat vehicula in. Aliquam laoreet enim vitae enim dictum, eu aliquet erat laoreet. In pulvinar tempor efficitur. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nunc vehicula lacus at purus tempor, et ultrices ante aliquet. Ut consectetur sodales sem fermentum molestie. Phasellus tristique enim at lacus feugiat iaculis.
                        </p>
                        <Box className={classes.projectTechnologies}>
                          <div className={classes.technologiesTitle}>Technologies Used:</div>
                          <ul className={classes.technologiesList}>
                            <li>React.js</li>
                            <li>Laravel</li>
                            <li>Wordpress</li>
                          </ul>
                        </Box>
                        <Button className={classes.projectViewButton} variant="contained">View Website</Button>
                      </Box>
                    </Grid>
                  </Grid>
               </Box>
            </Slide>
        </Modal>
      </section>
    </React.Fragment>
  );
}
