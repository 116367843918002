import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useStyles } from './headerStyles';

export default function Header({homePageData, scrollInto}) {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  }
  

  return (
    <React.Fragment>
      <Container className={classes.headerWrapper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <h1 className={classes.logo}>
              <span>Angelo</span>
              Bantiling
            </h1>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={7}>
            <div className={classes.headerMenu}>
              <div onClick={toggleDrawer} className={classes.mobileMenuButton}><MenuIcon /></div>
              <ul>
                <li>
                  <a  href="#about">About</a>
                </li>
                <li>
                  <a  href="#whatido">Services</a>
                </li>
                <li>
                  <a  href="#works">Works</a>
                </li>
                <li>
                  <a  href="#feedback">Feedback</a>
                </li>
                <li className={classes.headerMenuItemOutlined}>
                  <a  href="#contact">Hire Me</a>
                </li>
              </ul>
            </div>
            <Drawer
              anchor={'left'}
              open={openDrawer}
              onClose={toggleDrawer}
            >
              <div className={classes.headerMobileMenu}>
                <ul>
                  <li>
                    <a onClick={toggleDrawer} href="#about">About</a>
                  </li>
                  <li>
                    <a onClick={toggleDrawer} href="#whatido">Services</a>
                  </li>
                  <li>
                    <a onClick={toggleDrawer} href="#works">Works</a>
                  </li>
                  <li>
                    <a onClick={toggleDrawer} href="#feedback">Feedback</a>
                  </li>
                  <li className={classes.headerMenuItemOutlined}>
                    <a onClick={toggleDrawer} href="#contact">Hire Me</a>
                  </li>
                </ul>
              </div>
            </Drawer>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
