import React from 'react';
import { useRef } from 'react';
import { CustomPreloader } from 'react-preloaders2';
import Lottie from 'react-lottie';
import * as codingAnimation from '../../assets/animation/71619-coding.json';
import * as loadingAnimation from '../../assets/animation/Loading.json';
import { Modal, Box, Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { getHomePageData, getExperiencesData, getCertificatesData } from '../../services/homepageService';
import modalStatusEnum from '../../assets/constants/modalStatusEnum';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../layout/header/header';
import Footer from '../layout/footer/footer';
import Banner from './banner/banner';
import AboutMe from './aboutMe/aboutMe';
import WhatIDo from './whatIDo/whatIDo';
import AwesomeWorks from './awesomeWorks/awesomeWorks';
import Feedback from './feedback/feedback';
import Contact from './contact/contact';
import { useStyles } from './homeStyles';

function Home({isImagesLoading}){
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalDescription, setModalDescription] = useState(null);
    const [modalStatus, setModalStatus] = useState(0);
    const [isDataLoading, setDataLoading] = useState(true);
    const [homePageData, setHomePageData] = useState({});
    const [experiencesData, setExperiencesData] = useState([]);
    const [certificatesData, setCertificatesData] = useState([]);

    /* Scroll Refs */
   const aboutRef = useRef();
   const whatIdoRef = useRef();
   const worksRef = useRef();

    useEffect(() => {
        async function onLoad(){
            // Getting Home Page Data
            const response = await getHomePageData();
            setHomePageData(response.data[0]);

            // Experiences Data
            const experiencesResponse = await getExperiencesData();
            setExperiencesData(experiencesResponse.data);

            // Certificates Data
            const certificatesResponse = await getCertificatesData();
            setCertificatesData(certificatesResponse.data);
            setDataLoading(false);
        }
        onLoad();
    }, []);

    useEffect(() => {
        if (isDataLoading === false && isImagesLoading === false){
            setLoading(false);
        }
    },[isDataLoading, isImagesLoading]);

    const showModalMessage = (title, description, status) => {
        setModalTitle(title);
        setModalDescription(description);
        setModalStatus(status);
        setShowModal(true);
    }

    const hideModalMessage = () => {
        setShowModal(false);
        setTimeout(() => {
            setModalTitle(null);
            setModalDescription(null);
            setModalStatus(0);
        },200);
    }

    function scrollInto(section) {
        if (section === 'about'){
            alert('asd');
        }
        else if (section === 'whatido'){
            whatIdoRef.current.scrollIntoView();
        }
        else if (section === 'works'){
            worksRef.current.scrollIntoView();
        }
    }
 
    const codingOptions = {
      loop: true,
      autoplay: true, 
      animationData: codingAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        id: 'codingAnimation'
      }
    };
 
    const loadingOptions = {
      loop: true,
      autoplay: true, 
      animationData: loadingAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        id: 'loadingAnimation'
      }
    };

    return (
        <section className='homepage-wrapper'>
            {isDataLoading === false && (
                <React.Fragment>
                    <Header scrollInto={scrollInto} homePageData={homePageData} />
                        <Banner { ...homePageData } />
                        <section id="about">
                            <AboutMe homePageData={homePageData} experiencesData={experiencesData} certificatesData={certificatesData} />
                        </section>
                        <section id="whatido">
                            <WhatIDo homePageData={homePageData} />
                        </section>
                        <section id="works">
                            <AwesomeWorks { ...homePageData } />
                        </section>
                        <section id="feedback">
                            <Feedback { ...homePageData } />
                        </section>
                        <section id="contact">
                            <Contact hideModalMessage={hideModalMessage} showModalMessage={showModalMessage} homePageData={homePageData} />
                        </section>
                    <Footer { ...homePageData } />
                </React.Fragment>
            )}
            <div className={classes.loadingAnimation}>
                <CustomPreloader customLoading={isLoading}>
                    <Lottie options={codingOptions} />
                    <span className={classes.loadingText}>
                        <Lottie options={loadingOptions} />
                    </span>
                </CustomPreloader>
            </div>
                <Modal open={showModal}>
                    <Fade in={showModal}>
                        <Box className={classes.modalContainer}>
                            <div className={[classes.modalIcon, modalStatus === modalStatusEnum.MODAL_SUCCESS ? classes.modalIconSuccess : modalStatus === modalStatusEnum.MODAL_ERROR ? classes.modalIconError: ""].join(' ')}>
                                <div onClick={hideModalMessage} className={classes.modalCloseIcon}>
                                    <CloseIcon />
                                </div>
                                {modalStatus === modalStatusEnum.MODAL_SUCCESS ? <CheckCircleOutlineIcon /> : modalStatus === modalStatusEnum.MODAL_ERROR ? <ErrorOutlineIcon /> : ""}
                            </div>
                            <Box className={classes.modalContent}>
                                <h2 className={classes.modalTitle}>{ modalTitle }</h2>
                                <p className={classes.modalDescription}>{ modalDescription }</p>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
        </section>
    );
}

export default Home