import { makeStyles } from '@material-ui/styles'
import constants from '../../../assets/styles/global';
import BannerBackground from '../../../assets/images/banner.jpg';

export const useStyles = makeStyles({
    bannerWrapper: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '750px',
        zIndex: 1,
        overflow: 'hidden',
        '&:before':{
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(0,0,0,0.35)',
            zIndex: 3
        }
    },
    bannerBackground: {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${BannerBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center 0px',
        backgroundSize: 'cover',
        filter: 'grayscale(100%)',
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]:{
            backgroundPosition: 'center 0px',
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            position: 'absolute',
            backgroundPosition: 'center center',
            transform: 'none',
            left: 0,
        }
    },
    bannerTitle: {
        position: 'absolute',
        color: constants.COLOR_WHITE,
        top: '200px',
        left: 170,
        zIndex: 4,
        fontWeight: 700,
        fontSize: constants.FONT_XXL,
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]:{
            fontSize: constants.FONT_XL,
            left: 30,
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            position: 'relative',
            left: 0,
            textAlign: 'center'
        }
    },
    bannerDescription: {
        position: 'absolute',
        color: constants.COLOR_WHITE,
        top: '290px',
        left: 170,
        zIndex: 4,
        width: '480px',
        fontSize: constants.FONT_LG,
        fontWeight: 300,
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]:{
            fontSize: constants.FONT_LG,
            width: '370px',
            top: '270px',
            left: 30,
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            fontSize: constants.FONT_MD,
            position: 'relative',
            top: '210px',
            left: 0,
            textAlign: 'center',
            width: '100%'
        }
    },
    bannerJob: {
        fontWeight: 700,
        zIndex: 4,
    },
    bannerContactButton: {
        transition: 'all 0.3s ease-out',
        position: 'absolute',
        color: constants.COLOR_WHITE,
        top: '550px',
        left: 170,
        zIndex: 4,
        border: `3px solid ${constants.COLOR_WHITE}`,
        fontSize: constants.FONT_MD,
        fontWeight: 300,
        textDecoration: 'none',
        textTransform: 'uppercase',
        padding: '10px 26px',
        '&:hover':{
            background: constants.COLOR_WHITE,
            borderColor: constants.COLOR_WHITE,
            color: constants.COLOR_BLACK
        },
        [`@media (max-width:${constants.BREAKPOINT_SMALL_SCREEN})`]:{
            top: '510px',
            left: 30,
        },
        [`@media (max-width:${constants.BREAKPOINT_MOBILE})`]:{
            position: 'relative',
            left: 0,
            top: 250,
            fontSize: constants.FONT_SM,
            display: 'block',
            width: 140,
            margin: '0 auto',
        }
    }
});